import { addPropertyControls, ControlType } from "framer"
import { useState, useEffect, cloneElement } from "react"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ThemeVariants(props) {
    const [isDarkTheme, setIsDarkTheme] = useState(null)

    useEffect(() => {
        const updateTheme = () => {
            // For compatibility with the theme toggle component.
            // If your site does not have a theme toggle, the device theme will be used.
            const currentToggleState =
                localStorage.getItem("currentToggleState")

            // If the toggle theme was found in local storage, use it.
            if (currentToggleState !== null) {
                setIsDarkTheme(currentToggleState === "dark")
            } else {
                // If the toggle theme was not found in local storage, use the device theme.
                const mediaQuery = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                )
                setIsDarkTheme(mediaQuery.matches)
            }
        }

        // Event listener for changes in the device theme
        const osThemeChange = (e) => {
            setIsDarkTheme(e.matches)
        }

        // Update the theme when the page loads
        updateTheme()

        // Listen for theme changes made by the theme toggle component
        window.addEventListener("themeChange", updateTheme)

        // Listen for changes in the device theme
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
        mediaQuery.addListener(osThemeChange)

        // Clean up the event listeners when the theme toggle component unmounts
        return () => {
            window.removeEventListener("themeChange", updateTheme)
            mediaQuery.removeListener(osThemeChange)
        }
    }, [])

    // Before the theme has been determined, render nothing.
    // This prevents flashing while the page loads.
    if (isDarkTheme === null) {
        return null
    }

    let layers = [props.light[0], props.dark[0]]
    for (let i = 0; i < 2; i++) {
        let layer = layers[i]
        if (layer && (props.style?.width || props.style?.height)) {
            layers[i] = cloneElement(layer, {
                style: {
                    ...layer.props.style,
                    ...props.style,
                },
            })
        }
    }

    let themeLayer = layers[isDarkTheme ? 1 : 0]
    if (!themeLayer) {
        return <div style={{ minWidth: 100, minHeight: 100 }}></div>
    } else {
        return <>{themeLayer}</>
    }
}

ThemeVariants.displayName = "Theme Variants"

addPropertyControls(ThemeVariants, {
    light: {
        type: ControlType.ComponentInstance,
        title: "Light",
    },
    dark: {
        type: ControlType.ComponentInstance,
        title: "Dark",
    },
})
